.btn-base {
    height: 50px;
    width: 300px;
    flex-shrink: 0;

    &.btn_cancel {
        .btn {
            background: var(--dark_grey_color);
        }
    }

    &.btn_blue {
        .btn {
            background: #027bff;
        }
    }

    .btn {
        background: linear-gradient(94.97deg, #cb4eff 1.95%, #ff4ec1 99.1%);
        border-radius: 100px;
        padding: 0 30px;
        outline: none;
        border: none;
        font-size: 0.875rem;
        font-weight: 600;
        height: 100%;
        width: 100%;
        cursor: pointer;
        transition: 0.2s all;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;

        &:active {
            transition: 0.2s ease-in-out;
            scale: 0.9;
        }

        &:disabled {
            opacity: 0.6;
            // background: linear-gradient(94.97deg, #ca4eff73 1.95%, #ff4ec152 99.1%);
            cursor: default;

            &:active {
                scale: unset;
            }
        }
    }
}
